export const getAllUseServersUseCase = (data) =>
{
    if (data === null || data === undefined || data === {})
    {
        return [];
    }
    
    const serverNames = [];

    // Iterate through the top-level properties (e.g., "2023_01", "2022_05", etc.)
    for (const month in data) {
        const servers = data[month].servers;

        // Iterate through the server names within each month
        for (const serverName in servers) {
            serverNames.push(serverName);
        }
    }

    return serverNames;
}