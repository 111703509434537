import { Cache } from "../../../utils/cache/index";
import { IFetcherFramework } from "../entitie/IFetcherFramework";
import { MetricsFramework } from "../framework/metricsFramework";
import { ResponseToServerOBject} from "../entitie/MetricEntitie";

// For being ble to call store from here
import { SetServerMetrics } from "../../../store/store";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";

const CACHE_TIMEOUT = 60 + 10;
const REVOKE_AFTER_TIMEOUT = true;
const VARIABLE_NAME = "raw_server_metrics_resp";

const cache = new Cache(CACHE_TIMEOUT, REVOKE_AFTER_TIMEOUT, VARIABLE_NAME);
var lastKey = '';
var instance = null;


export class ServerMetricsFramework
{
    constructor(token) {
        if (instance !== null)
        {
            // Update token if requested
            instance.metricFw.implementor.token = token;
            return instance;
        }

        const metrics = new MetricsFramework(token);
        this.metricFw = new IFetcherFramework(metrics);

        // save ourself
        instance = this;
    }

    DeleteCache()
    {
        cache.delete();
    }

    GetFromCache(startMonthVar, endMonthVar)
    {
        const cacheKey = `${startMonthVar}${endMonthVar}`;
        if (cache.state === null)
        {
            //return cache.load();
            return cache.loadPrefix(cacheKey);
        }

        if (lastKey !== cacheKey)
        {
            lastKey = cacheKey;
            return cache.loadPrefix(cacheKey);
        }
        
        return cache.state;
    }

    async GetMetrics(startMonthVar, endMonthVar)
    {
        return await this.metricFw.FetchFromServer(startMonthVar, endMonthVar);
    }

    async FetchFromServer(setNew, startMonthVar, endMonthVar)
    {
        if (isNullOrUndefined(this.metricFw.implementor.token) === true)
        {
            return null;
        }

        const cacheResp = this.GetFromCache(startMonthVar, endMonthVar);
        if (cacheResp !== null)
        {
            // Set the state with new data
            setNew(SetServerMetrics(JSON.stringify(cacheResp)));
            return;
        }

        // if we got here - we need to fetch it from server
        const metrics = await this.GetMetrics(startMonthVar, endMonthVar);

        // if we dont have ens.
        if (metrics.statusCode !== 200)
        {
            return null;
        }

        // if response valid - save in cache
        //cache.save(ResponseToServerOBject(metrics.data));
        cache.savePrefix(ResponseToServerOBject(metrics.data), `${startMonthVar}${endMonthVar}`);


        // Set the state with new data
        setNew(SetServerMetrics(JSON.stringify(ResponseToServerOBject(metrics.data))));
    }
};