import * as React from 'react';
import BasicSelect from "../Selectors/SelectValue";
import Grid from '@mui/material/Grid';

const Years = ["2023", "2024","2025","2026", "2027", "2028", "2029", "2030"];
const Month = ["01", "02","03","04", "05", "06", "07", "08", "09", "10", "11", "12", "13"];


export default function YearMonthSelect({labelList, startsLists, setterLists, optionListOfList}) {
  if (startsLists === undefined || startsLists.length !== 2)
  {
    return null;
  }

  return (
    <Grid container spacing={2}>
        <Grid item>
            <BasicSelect
                label={labelList[0]}
                startVal={startsLists[0]}
                setter={setterLists[0]}
                optionList={Years}
            />
        </Grid>

        <Grid item>
            <BasicSelect
                label={labelList[1]}
                startVal={startsLists[1]}
                setter={setterLists[1]}
                optionList={Month}
            />
        </Grid>
    </Grid>
  );
}