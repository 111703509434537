const getDate = (dateString) => {
    const [yearMonth, day] = dateString.split(":");
    const [year, month] = yearMonth.split("_");

    // Create a Date object
    return new Date(`${year}-${month}-${day}T00:00:00Z`);
}

const byteToGigabit = (bytes) => {
    return bytes/125_000_000;
}

const isNUllOrUndeff = (obj) =>
{
    return obj === null || obj === undefined;
}

export const getAllResponseTimeResponseCode = (data, _serverName) =>
{
    if (isNUllOrUndeff(data) || isNUllOrUndeff(_serverName) || data === {})
    {
        return [[], [], []];
    }

    const keysInResponseTime = [];
    const keysInResponseTimeLatency = [];
    const respStatistics = [];
    for (const month in data) {
        const servers = data[month].servers;
        for (const serverName in servers) {
            if (serverName !== _serverName)
            {
                continue;
            }

            for (const day in servers[serverName].days) {
                for (const cacheKey in servers[serverName].days[day].responseTimeStat.latency)
                {
                    for (const innerKey in servers[serverName].days[day].responseTimeStat.latency[cacheKey])
                    {
                        for (const statistics in servers[serverName].days[day].responseTimeStat.latency[cacheKey][innerKey])
                        {
                            if (respStatistics.includes(statistics))
                            {
                                continue;
                            }

                            respStatistics.push(statistics);
                        }

                        if (keysInResponseTimeLatency.includes(innerKey))
                        {
                            continue;
                        }

                        keysInResponseTimeLatency.push(innerKey);
                    }

                    if (keysInResponseTime.includes(cacheKey))
                    {
                        continue;
                    }

                    keysInResponseTime.push(cacheKey);
                }
            }
        }
    }

    return [keysInResponseTime, keysInResponseTimeLatency, respStatistics];
}

export const getMetricsPerSecerUseCase = (data, _serverName) =>
{
    if (data === null || data === undefined || data === {})
    {
        return {};
    }

    if (_serverName === null || _serverName === undefined || _serverName === '')
    {
        return {};
    } 
    

    const bw = {
        dates: [],
        data: []
    };

    const rps = {
        dates: [],
        data: []
    };

    const errRate = {
        dates: [],
        data: []
    };


    const cacheRat = {
        dates: [],
        data: {},
    };

    const responseTimeStat = {
        dates: [],
        data: {}
    };

    const keysInCacheRet = [];
    for (const month in data) {
        const servers = data[month].servers;
        for (const serverName in servers) {
            if (serverName !== _serverName)
            {
                continue;
            }

            for (const day in servers[serverName].days) {
                for (const cacheKey in servers[serverName].days[day].cacheRat.ratios)
                {
                    if (keysInCacheRet.includes(cacheKey))
                    {
                        continue;
                    }

                    keysInCacheRet.push(cacheKey);
                }
            }
        }
    }


    const keysInResponseTime = [];
    const keysInResponseTimeLatency = [];
    const respStatistics = [];
    for (const month in data) {
        const servers = data[month].servers;
        for (const serverName in servers) {
            if (serverName !== _serverName)
            {
                continue;
            }

            for (const day in servers[serverName].days) {
                for (const cacheKey in servers[serverName].days[day].responseTimeStat.latency)
                {
                    for (const innerKey in servers[serverName].days[day].responseTimeStat.latency[cacheKey])
                    {
                        for (const statistics in servers[serverName].days[day].responseTimeStat.latency[cacheKey][innerKey])
                        {
                            if (respStatistics.includes(statistics))
                            {
                                continue;
                            }

                            respStatistics.push(statistics);
                        }

                        if (keysInResponseTimeLatency.includes(innerKey))
                        {
                            continue;
                        }

                        keysInResponseTimeLatency.push(innerKey);
                    }

                    if (keysInResponseTime.includes(cacheKey))
                    {
                        continue;
                    }

                    keysInResponseTime.push(cacheKey);
                }
            }
        }
    }

    // Iterate through the top-level properties (e.g., "2023_01", "2022_05", etc.)
    for (const month in data) {
        const servers = data[month].servers;

        // Iterate through the server names within each month
        for (const serverName in servers) {
            if (serverName !== _serverName)
            {
                continue;
            }

            // Sort the keys as zero-padded numbers
            const sortedDay = Object.keys(servers[serverName].days);
            sortedDay.sort((a, b) => {
                // Convert keys to integers while preserving leading zeros
                const aNumber = parseInt(a, 10);
                const bNumber = parseInt(b, 10);
            
                return aNumber - bNumber;
            });

            const sortedArray = sortedDay.map((key) => key);


            sortedArray.forEach((day) => {
                const fullDate = getDate(`${month}:${day}`);
                bw.dates.push(fullDate);
                rps.dates.push(fullDate);
                errRate.dates.push(fullDate);
                cacheRat.dates.push(fullDate);
                responseTimeStat.dates.push(fullDate);


                bw.data.push(byteToGigabit(servers[serverName].days[day].bwPerSecondStat.max));
                rps.data.push(servers[serverName].days[day].requestsPS.max);
                errRate.data.push(servers[serverName].days[day].errorRat.total);
                

                keysInCacheRet.forEach((cacheKey) =>
                {
                    if (cacheRat.data[cacheKey] === undefined || cacheRat.data[cacheKey] === null)
                    {
                        cacheRat.data[cacheKey] = [];
                    }

                    var val = servers[serverName].days[day].cacheRat.ratios[cacheKey];
                    if (val === null || val === undefined)
                    {
                        val = 0;
                    }
                    cacheRat.data[cacheKey].push(val);
                });
                
                keysInResponseTime.forEach((response_code) =>
                    {
                        keysInResponseTimeLatency.forEach((cache_status) =>{
                            respStatistics.forEach((statistics) => {
                                const keyName = `${response_code}_${cache_status}_${statistics}`;
                                if ( isNUllOrUndeff(responseTimeStat.data[keyName]) )
                                {
                                    responseTimeStat.data[keyName] = [];
                                }

                                if (isNUllOrUndeff(servers[serverName].days[day].responseTimeStat.latency[response_code]) || 
                                isNUllOrUndeff(servers[serverName].days[day].responseTimeStat.latency[response_code][cache_status]) ||
                                isNUllOrUndeff(servers[serverName].days[day].responseTimeStat.latency[response_code][cache_status][statistics]))
                                {
                                    responseTimeStat.data[keyName].push(0);
                                }
                                else
                                {
                                    responseTimeStat.data[keyName].push(servers[serverName].days[day].responseTimeStat.latency[response_code][cache_status][statistics]);
                                }
                            });
                        });
                });
            });
        }
    }

    return {bw, rps, 
        errRate,
        cacheRat,
        responseTimeStat,};
}