import axios from "../../../projectAxios";
import { FetcherResponse } from "../entitie/IFetcherFramework";

const URL_TO_FETCH_FROM = "/Usage/GetServersMetrics";

export class MetricsFramework
{
    constructor(token) {
        this.token = token;
    }

    async FetchFromServer(startMonthVar, endMonthVar)
    {
        try {
            const resp = await axios.get(
                URL_TO_FETCH_FROM + `?startMonth=${startMonthVar}&endMonth=${endMonthVar}`,
                {
                  headers: {
                    Authenticate: this.token,
                  },
                },
                {}
              );

            return new FetcherResponse(resp.status, resp.data);
          } catch (error) {
            return new FetcherResponse(error.response.status, null);
          }
    }
};