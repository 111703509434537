export const ResponseToServerOBject = (data) =>
{
    // Get the actual obecjt
    console.log(data, data?.obj?.serverInfo?.monthToServer);
    //const data = JSON.parse(resp);
    const serverMetrics = data?.serverInfo?.monthToServer || {};
    return serverMetrics;

    const serverMetricsViews = Object.keys(serverMetrics).map((month) => {
        const serverList = serverMetrics[month]["servers"];
        Object.keys(serverList).forEach((serverId) => {
            console.log("serverId", serverId);
            Object.keys(serverList[serverId]["days"]).forEach((dayId) => {
                console.log("dayId", dayId);
                console.log("dayobj", serverList[serverId]["days"][dayId]);
                console.log("bw_max", serverList[serverId]["days"][dayId].bwPerSecondStat.max);
                console.log("cacheRat", serverList[serverId]["days"][dayId].cacheRat);
                console.log("responseTimeStat", serverList[serverId]["days"][dayId].responseTimeStat);
                console.log("requestsPS", serverList[serverId]["days"][dayId].requestsPS);
            });
              //const serverData = serverList[serverId];
              // Do something with the serverData
              //console.log(`Server ${serverId}:`, serverData);
        });
        
        /*console.log("serverData", serverData);
        console.log("serverId", serverId);
        const serverMetricsArray = Object.keys(serverData).map((date) => serverData[date].bwPerSecondStat.max);
        
        console.log("serverMetricsArray", serverMetricsArray);*/
        //console.log("serverData.bwPerSecondStat.max", serverData.bwPerSecondStat.max);
    
      });
}

/*
dayobj {
    bwPerSecondStat: { max: 54225 },
    cacheRat: { ratios: { MISS: 0.8279662484605912, '': 0.7709818274712523 } },
    errorRat: { total: 0 },
    responseTimeStat: { latency: { '200': [Object] } },
    requestsPS: { max: 8 }
  }*/