import React, { useState, useEffect } from 'react';
import { navigate } from "gatsby";
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import useMediaQuery from '@mui/material/useMediaQuery';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Layout from '../components/layout';
import Stack from '@mui/material/Stack';
import {IFetcherFramework} from "../services/server_metrics_service/entitie/IFetcherFramework";
import { ServerMetricsFramework } from "../services/server_metrics_service/framework/ServerMetricsFramework";
import { LineChart } from '@mui/x-charts/LineChart';
import  YearMonthSelect  from "../components/Selectors/SelectYearMonth";
import BasicSelect from "../components/Selectors/SelectValue";
import { getAllUseServersUseCase } from "../services/server_metrics_service/use_cases/GetAllServersUsecase";
import { getMetricsPerSecerUseCase } from "../services/server_metrics_service/use_cases/GetMetricsPerSecerUseCase";
import { getAllResponseTimeResponseCode } from "../services/server_metrics_service/use_cases/GetMetricsPerSecerUseCase";

import {
  Card,
  CardHeader
} from "@mui/material";

// Function to check if all values in an array are 0
const allValuesAreZero = (arr) => arr.every((value) => value === 0);


var startDate = [new Date().getFullYear(), new Date().toLocaleDateString("en-GB", { month: "2-digit",})];
var endDate = [new Date().getFullYear(), new Date().toLocaleDateString("en-GB", { month: "2-digit",})];


function resizeListByRange(list, range) {
  const [start, end] = range;
  
  // Ensure start and end values are within the valid range [0, list.length]
  const startIndex = Math.max(0, start);
  const endIndex = Math.min(list.length, end);
  
  // Use array slicing to create a new resized list
  const resizedList = list.slice(startIndex, endIndex);
  
  return resizedList;
}

function valuetext(value) {
  return `${value}°C`;
}

const Metrics = () => {
    const dispatch = useDispatch();
    const isBigScreen = useMediaQuery('(min-width:600px)');
    const { isLoggedIn, token, serverMetrics } = useSelector((state) => {
        return {
        isLoggedIn: state?.isLoggedIn || false,
        token: state?.token || null,
        serverMetrics: state?.serverMetrics || null,
        };
    });

    const [startYear, seStartYear] = useState(startDate[0]);
    const [startMonth, setStartMonth] = useState(startDate[1]);
    const [endYear, seEndYear] = useState(endDate[0]);
    const [endMonth, setEndMonth] = useState(endDate[1]);

    const _seStartYear = (s) =>
    {
        seStartYear(s);
        startDate[0] = s;
    }

    const _setStartMonth = (s) =>
    {
      setStartMonth(s);
      startDate[1] = s;
    }

    const _seEndYear = (s) =>
    {
      seEndYear(s);
      endDate[0] = s;
    }

    const _setEndMonth = (s) =>
    {
      setEndMonth(s);
      endDate[1] = s;

      const helper = new ServerMetricsFramework(token)
        const metricFramework = new IFetcherFramework(helper);
      metricFramework.FetchFromServer(dispatch, `${startYear}_${startMonth}`, `${endYear}_${s}`).then( resp => { 
      });
    }
    
    const serverList = getAllUseServersUseCase(serverMetrics);
    const [server, setServer] = useState('');

    const perServer = getMetricsPerSecerUseCase(serverMetrics, server);
    const [response_status, cache_status, available_statistics] = getAllResponseTimeResponseCode(serverMetrics, server);
    const [responSestatusToShow, setResponSestatusToShow] = useState(response_status !== null && response_status !== undefined && response_status.length > 0 ? response_status[0] : '');

    /* for range */
    const [rangeValue, setRangeValue] = React.useState([20, 37]);
    const [rangeMaxValue, setRangeMaxValue] = React.useState(100);

    const handleChange = (event, newValue) => {
      setRangeValue(newValue);
    };

    useEffect(() => {
        if (isLoggedIn === false || token === null || token === undefined)
        {
          navigate("/login");
          return;
        }
    
        const helper = new ServerMetricsFramework(token)
        const metricFramework = new IFetcherFramework(helper);
    
        // this will update the state on finish if there is what to update
        if (serverMetrics === null || serverMetrics === undefined)
        {
          metricFramework.FetchFromServer(dispatch, `${startYear}_${startMonth}`, `${endYear}_${endMonth}`).then( resp => { 
          });
        }
        else
        {
          //setisVideoUsageStorage(false);
        }
        
      }, [isLoggedIn,  dispatch,  token, serverMetrics, startYear, startMonth, endYear,  endMonth, server, responSestatusToShow]);

    

    const getCard = (_title, xData, seriesOfData) => 
    {
      var newData = resizeListByRange(xData, rangeValue);
      seriesOfData.forEach(element => {
        element.data = resizeListByRange(element.data, rangeValue);
      });

      return (
        <Card>
          <CardHeader title={_title} style={{color:"#757575"}}/>
          <LineChart
            xAxis={[{ data: newData, scaleType: 'time'}]}
            series={seriesOfData}
            height={300}
        />
      </Card>
    
        ); 
    }

    const isNotReadyToLoadLineChart = (serverDAta, obj) =>
    {
      return serverDAta === null || serverDAta === undefined || Object.keys(serverDAta).length === 0 ||
      obj === null || obj === undefined;
    }
    // bwPerSecond
    const bw = isNotReadyToLoadLineChart(serverMetrics, perServer.bw) ? null : getCard("Bandwidth/sec (gbps)", perServer.bw.dates, [
      {
        data: perServer.bw.data,
      },
    ]);

    // requestRPS
    const rps = isNotReadyToLoadLineChart(serverMetrics, perServer.rps) ? null : getCard("Request/sec", perServer.rps.dates, [
      {
        data: perServer.rps.data,
      },
    ]);

    // Error rat
    const err = isNotReadyToLoadLineChart(serverMetrics, perServer.errRate) ? null : getCard("Errors Rate", perServer.errRate.dates, [
      {
        data: perServer.errRate.data,
      },
    ]);

    if (isNotReadyToLoadLineChart(serverMetrics, perServer.bw) === false && perServer.bw !== null && perServer.bw !== undefined)
    {
      if (rangeMaxValue !== perServer.bw.dates.length)
      {
        setRangeValue([0, perServer.bw.dates.length]);
        setRangeMaxValue(perServer.bw.dates.length);
      }
    }

    const cache = isNotReadyToLoadLineChart(serverMetrics, perServer.cacheRat) ? null : getCard("Cache Ratios", perServer.errRate.dates, Object.entries(perServer.cacheRat.data).map((value, index) => {
      return {
        id: value[Object.keys(value)[0]],
        label: value[Object.keys(value)[0]],
        data: perServer.cacheRat.data[value[Object.keys(value)[0]]]
      };
    }));

    const reponseTime = isNotReadyToLoadLineChart(serverMetrics, perServer.responseTimeStat) ? null : getCard("Response Time Statistics", perServer.responseTimeStat.dates, Object.entries(perServer.responseTimeStat.data).reduce((acc, [key, value], index) => {
      if (allValuesAreZero(value)) {
        return acc;
      }

      if (!key.startsWith(responSestatusToShow)) {
        return acc;
      }
    
      acc.push({
        id: key,
        label: key,
        data: value,
      });
    
      return acc;
    }, []));


    const tables = serverMetrics === {} ? null : (
      <>
        {bw}
        {rps}
        {err}
        {cache}

        <BasicSelect
            label={"ResponseStatus"}
            startVal={responSestatusToShow}
            setter={setResponSestatusToShow}
            optionList={response_status}
        />
        {reponseTime}
      </>);

    return (
        <>
          <Helmet>
              <title>Metrics</title>
          </Helmet>
    
          <Layout userName={"SomeAdmin"} canReadServerMetrics={true}>

          <Stack spacing={2}>
            <YearMonthSelect
              labelList={["startYear", "startMonth"]}
              startsLists={[startYear, startMonth]}
              setterLists={[_seStartYear, _setStartMonth]}
              optionListOfList={null}
            />

            <YearMonthSelect
              labelList={["endYear", "endMonth"]}
              startsLists={[endYear, endMonth]}
              setterLists={[_seEndYear, _setEndMonth]}
              optionListOfList={null}
            />

            <BasicSelect
                label={"SelectServer"}
                startVal={server}
                setter={setServer}
                optionList={serverList}
            />

            { /* Slider to choose timeframe to show */}
            <Box sx={{ width: 300 }}>
              <Slider
                getAriaLabel={() => 'Dates pointes range'}
                value={rangeValue}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                max={rangeMaxValue}
              />
            </Box>

            {tables}
          </Stack>


          {/* for choosed server - put all the metrics */}
         


          </Layout>
        </>
      )
}

export default Metrics;
